<app-dialog-header [title]="data.title" [dialogRef]="dialogRef"></app-dialog-header>
<mat-dialog-content>
  @if (data.content) {
    @for (content of data.content; track content) {
      <div
        class="content-body"
        [class.scoped]="content.scope"
        [class.warning]="content.scope === 'warning'"
        [class.danger]="content.scope === 'danger'"
      >
        @if (content.scope) {
          <div class="scope-header">
            <lucide-icon
              [img]="icons.get(content.scope)"
              [size]="20"
              class="expand-more"
            ></lucide-icon>
            <span class="scope-title">{{ content.title }}</span>
          </div>
        }
        <div
          [class.scope-content]="content.scope"
          [innerHTML]="content.message"
        ></div>
      </div>
    }
    <div>
      @if (data.checkBox) {
        <mat-checkbox
          class="checkbox"
          [ngModel]="checked"
          (ngModelChange)="checked = $event"
        ></mat-checkbox>
      }
      {{ data.checkBox }}
    </div>
  }
</mat-dialog-content>
<div class="dialog-actions">
  @for (button of data.buttons; track button.text) {
    <app-new-action-button
      [text]="button.text"
      (actionCompleted)="action(button.action)"
      [themedButton]="button.theme"
      [icon]="button.icon"
    ></app-new-action-button>
  }
</div>
