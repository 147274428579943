import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LucideAngularModule, X } from 'lucide-angular';
import { LucideIconData } from 'lucide-angular/icons/types';

@Component({
  selector: 'app-dialog-header',
  standalone: true,
  imports: [CommonModule, LucideAngularModule],
  templateUrl: './dialog-header.component.html',
  styleUrl: './dialog-header.component.scss',
})
export class DialogHeaderComponent {
  readonly X: LucideIconData = X;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  @Input() title = '';
  @Input() dialogRef!: any;
  @Input() showBottomBorder: boolean = false;

  constructor() {}

  close() {
    this.dialogRef.close();
  }
}
