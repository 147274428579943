import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '@root/app/shared/components/dialog/dialog.component';
import { DialogParams } from 'src/models/global-interfaces';

export interface DialogPayload<T> {
  payload: T;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private defaultWidth = '500px';
  private defaultMaxHeight = '94vh';
  private defaultDisableClose = true;

  constructor(private dialog: MatDialog) {}

  open<T>(params: DialogParams<T | DialogComponent>) {
    /* eslint-disable @typescript-eslint/typedef */
    const component = params.component || DialogComponent;
    const dialogRef = this.dialog.open(component, {
      width: params.width ?? this.defaultWidth,
      maxHeight: params.maxHeight ?? this.defaultMaxHeight,
      minHeight: params.minHeight ?? 'auto',
      disableClose: params.disableClose ?? this.defaultDisableClose,
      hasBackdrop: params.hasBackdrop ?? true,
      panelClass: params.customStyleClass ?? '',
      data: {
        title: params.title,
        content: params.content,
        okLabel: params.okLabel ?? 'Ok',
        cancelLabel: params.cancelLabel ?? 'Cancel',
        onConfirm: params.onConfirm,
        onCancel: params.onCancel,
        payload: params.payload,
        filterType: params.filterType,
        secondary: params.secondary,
        template: params.template,
        getItems: params.getItems,
        body: params.body,
        options: params.options,
        actions: params.actions,
        checkBox: params.checkBox,
        buttons: params.buttons,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.openTasksOption === true) {
        params.onConfirm(true);
      } else if (result?.openTasksOption === false) {
        params.onConfirm(false);
      } else {
        if (result) {
          if (params.onConfirm) {
            params.onConfirm(result);
          }
          if (params.closeDialog) {
            this.dialog.closeAll();
          }
        } else {
          if (params.onCancel) {
            params.onCancel();
          }
        }
      }
    });
    return dialogRef;
  }

  /* eslint-disable @typescript-eslint/typedef */

  openChild<T>(dialog: MatDialog, params: DialogParams<T | DialogComponent>) {
    const component = params.component || DialogComponent;
    const dialogRef = dialog.open(component, {
      width: params.width ?? this.defaultWidth,
      maxHeight: params.maxHeight ?? this.defaultMaxHeight,
      minHeight: params.minHeight ?? 'auto',
      disableClose: params.disableClose ?? this.defaultDisableClose,
      hasBackdrop: params.hasBackdrop ?? true,
      data: {
        title: params.title,
        content: params.content,
        okLabel: params.okLabel ?? 'Ok',
        cancelLabel: params.cancelLabel ?? 'Cancel',
        onConfirm: params.onConfirm,
        onCancel: params.onCancel,
        payload: params.payload,
        secondary: params.secondary,
        template: params.template,
        body: params.body,
        actions: params.actions,
        buttons: params.buttons
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (params.onConfirm) {
          params.onConfirm(true);
        }
      } else {
        if (params.onCancel) {
          params.onCancel();
        }
      }
      /* eslint-disable @typescript-eslint/no-explicit-any */
      /* eslint-disable @typescript-eslint/typedef */
    });
    return dialogRef;
  }

  confirmChanges(currentDialog: MatDialogRef<any>) {
    const dialogRef = this.open({
      title: `Unsaved Changes`,
      content: [
        {
          scope: 'danger',
          title: 'Warning',
          message: 'If you continue your unsaved changes will be lost.',
        },
        {
          message: 'You have unsaved changes, are you sure you want to continue?'
        }
      ],
      closeDialog: false,
      buttons: [
        {
          text: 'Cancel',
          theme: 'secondary',
          action: () => true
        },
        {
          text: 'Discard Changes',
          theme: 'primary',
          action: () => {
            currentDialog.close();
            return true;
          }
        }
      ]
    });
    return dialogRef;
  }
}
