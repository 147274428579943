import { ChangeDetectionStrategy, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogModule,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { StatusIcons } from '@root/models/global-interfaces';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { LucideAngularModule } from 'lucide-angular';
import { NewActionButtonComponent } from "../new-action-button/new-action-button.component";
import { LucideIconData } from 'lucide-angular/icons/types';

export interface DialogButtonPayload {
  checked?: boolean;
}

export interface DialogButtonConfig<> {
  text: string;
  icon?: LucideIconData;
  theme: string;
  // Returning true will close the dialog, false will keep it open
  action: (payload: DialogButtonPayload) => boolean;
}

export interface DialogData {
  title: string;
  content: DialogContent[];
  buttons: DialogButtonConfig[];
  checkBox?: boolean;
}

export interface DialogContent {
  scope?: string;
  title?: string;
  message?: string;
}

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    DialogHeaderComponent,
    MatCheckbox,
    FormsModule,
    LucideAngularModule,
    NewActionButtonComponent
],
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  @ViewChild('dialogHeader', { static: true })
  dialogHeader!: TemplateRef<DialogHeaderComponent>;
  icons = StatusIcons;
  checked = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  check() {
    this.checked = !this.checked;
  }

  action(buttonAction: (payload: DialogButtonPayload) => boolean) {
    if (buttonAction({
      checked: this.checked
    })) {
      this.dialogRef.close();
    }
  }
}
